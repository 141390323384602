<template>
  <div class="">
    <div class="flex justify-end text-sm font-medium text-white">
      <a href="/" class="px-2">EN</a> |
      <a href="/bm" class="px-2">BM</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>