<template>
  <div class="">
    <div class="flex justify-center pt-2 font-medium text-white md:justify-end">
      <a href="/" class="px-2">English</a> |
      <a href="/bm" class="px-2">Bahasa Malaysia</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>